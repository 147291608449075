export class ErrorConstants {
  public static ApiErrorMsg = {
    0: 'No data available for API.',
    404: 'No data available',
    501: 'Something went wrong.',
    500: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static WebhookErrorMsg = {
    0: 'No data available for webhooks.',
    404: 'No data available',
    501: 'Something went wrong.',
    500: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static subscriptionErrorMsg = {
    0: 'No data available for subscriptions.',
    404: 'No data available',
    501: 'Something went wrong.',
    500: 'Something went wrong.',
    502: 'Something went wrong.',
    403: 'This action is unauthorized.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static successTransactionErrorMsg = {
    0: 'No data available for successful transactions.',
    404: 'No data available',
    501: 'Something went wrong with successful transaction data.',
    500: 'Something went wrong with successful transaction data.',
    502: 'Something went wrong with successful transaction data.',
    503: 'Something went wrong with successful transaction data.',
    504: 'Something went wrong with successful transaction data.',
  };
  public static unSuccessTransactionErrorMsg = {
    0: 'No data available for unsuccessful transactions.',
    404: 'No data available',
    501: 'Something went wrong with unsuccessful transaction data.',
    500: 'Something went wrong with unsuccessful transaction data.',
    502: 'Something went wrong with unsuccessful transaction data.',
    503: 'Something went wrong with unsuccessful transaction data.',
    504: 'Something went wrong with unsuccessful transaction data.',
  };
  public static disbursementReportErrorMsg = {
    0: 'No data available for disbursement reports.',
    404: 'No data available',
    501: 'Something went wrong.',
    500: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static subscriptionDetailErrorMsg = {
    0: 'No data available for subscriptions.',
    404: 'No data available',
    500: 'Something went wrong.',
    403: 'This action is unauthorized.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static cycleErrorMsg = {
    0: 'No data available for plans.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    403: 'This action is unauthorized.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static cycleDetailErrorMsg = {
    0: 'No data available for plans.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    403: 'This action is unauthorized.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static couponErrorMsg = {
    0: 'No data available for discounts.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    403: 'This action is unauthorized.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static couponDetailErrorMsg = {
    0: 'No data available for discounts.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    403: 'This action is unauthorized.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static accountErrorMsg = {
    0: 'No data available for account.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static accountDetailErrorMsg = {
    0: 'No data available for account.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static transferErrorMsg = {
    0: 'No data available for invoice.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static transferDetailErrorMsg = {
    0: 'No data available for invoice.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static collectedFeesErrorMsg = {
    0: 'No data available for collected fees.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static collectedFeesDetailErrorMsg = {
    0: 'No data available for collected fees.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static invoiceErrorMsg = {
    0: 'No data available for invoices.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    403: 'This action is unauthorized.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static invoiceDetailErrorMsg = {
    0: 'No data available for invoices.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    403: 'This action is unauthorized.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static paymentErrorMsg = {
    0: 'No data available for payments.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
   public static payeeErrorMsg = {
    0: 'No data available for payees.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static paymentDetailErrorMsg = {
    0: 'No data available for payments.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static campaignsErrorMsg = {
    0: 'No data available for campaigns.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static disputeErrorMsg = {
    0: 'No data available for disputes.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static disputeDetailErrorMsg = {
    0: 'No data available for disputes.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };

  public static disputeHistoryErrorMsg = {
    0: 'No data available for disputes history.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };

  public static payoutErrorMsg = {
    0: 'No data available for payouts.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static payoutDetailErrorMsg = {
    0: 'No data available for payouts.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static transactionErrorMsg = {
    0: 'No data available for transactions.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static transactionDetailErrorMsg = {
    0: 'No data available for transactions.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static customerErrorMsg = {
    0: 'No data available for customers.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static achRejectErrorMsg = {
    0: 'No data available for ach rejects.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static zenDeskErrorMsg = {
    0: 'No data available for zen desk tickets.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static customerDetailErrorMsg = {
    0: 'No data available for customers.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static eventErrorMsg = {
    0: 'No data available for events.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static eventDetailErrorMsg = {
    0: 'No data available for events.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static roleErrorMsg = {
    0: 'No data available for roles.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static roleDetailErrorMsg = {
    0: 'No data available for role.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static userErrorMsg = {
    0: 'No data available for users.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static userDetailErrorMsg = {
    0: 'No data available for users.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static reviewErrorMsg = {
    0: 'No data available for review.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
  public static statementErrorMsg = {
    0: 'No data available for statements.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
    403: 'This action is unauthorized.',
  };
  public static statementtransactionErrorMsg = {
    0: 'No data available for Merchant statement.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
    403: 'This action is unauthorized.',
  };
  public static createInvoiceErrorMsg = {
    0: 'No data available for create invoice.',
    404: 'No data available',
    500: 'Something went wrong.',
    501: 'Something went wrong.',
    502: 'Something went wrong.',
    503: 'Something went wrong.',
    504: 'Something went wrong.',
  };
}
