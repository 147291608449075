<div class="dropdown" dropdown #dropdown="bs-dropdown"  (onHidden)="onDropHide(dropdown)" id="filter" (click)="Toggle() ; Show()">
  <button id="button-basic" type="button" class="filter-button">
    <i class="fa mr-1 fa-filter"></i>
    <span>Filter</span><span *ngIf="countFlag">{{' | ' + count}}</span>
  </button>
</div>


<div class="dropdown-menu dropdown-menu-right shadow bg-white" id="dropdown-menu" *ngIf="showHide" (click)="$event.stopPropagation()">
  <div class="container-fluid ">
    <div class="row filter-top">
      <div class="col-3 pr-0 text-left">
        <button class="btn btn-xs btn-clear mt-1" (click)="$event.stopPropagation(); clear()"> Clear</button>
      </div>
      <div class="col-6 text-center filterText">
        Filters
      </div>
      <div class="col-3 text-right">
        <button class="btn btn-xs btn-done mt-1 mb-1" (click)="done(dropdown); Toggle(); Show()">Done</button>
      </div>
    </div>
    <div class="row component" (click)="setCalanderFlag()" *ngFor="let component of filter">
      <div class="col-md-12">
        <div class="row component-head" (click)="component.checked = !component.checked; filterCount(component.checked);">
          <div class="col-md-12">
            <input type="checkbox" name="option" (change)="filterCount(component.checked)" (click)="$event.stopPropagation()" [(ngModel)]="component.checked"/><span class="heading ml-2 ophead">{{component.heading}}</span>
          </div>
        </div>
        <div class="row component-body" [@expend]="component.checked.toString()" *ngIf="component.componentType !== 'text'">
          <div class="col-md-12">
            <div class="row" *ngIf="component.options">
              <div class="col-md-12">
                <select class="selectOption" name="optionInput"   [(ngModel)]='component.selectedOption'>
                  <option *ngFor="let option of component.options; " [value]="option.key">{{option.value}}</option>
                </select>
              </div>
            </div>
            <div class="row" *ngIf="component.componentType === 'date' && (!component.selectedOption || component.selectedOption === '[intl]=' )">
              <div class="col-md-12">
                <input type="number" min="0" class="timeCountInput" name="timeCountInput" [(ngModel)]="component.dayCount" />

                <select class="select no_of_days" name="optionInput" [(ngModel)]='component.selectedSubOption'>
                  <option *ngFor="let subOpt of component.subOption" [value]="subOpt.key">{{subOpt.value}}</option>
                </select>
              </div>
            </div>
            <div class="row" *ngIf="component.componentType ==='date' && component.selectedOption === 'between'" >
              <div class="col-md-6 pr-1">
                <input class="calendar-textbox" placement="left" (onHidden)="setCalanderFlag()"    placeholder="From" bsDatepicker [(ngModel)]="component.dateFrom" [outsideClick]="true"  [bsConfig]="{ dateInputFormat : 'MM/DD/YYYY', showWeekNumbers:false }"  />
              </div>
              <div class="col-md-6 pl-1">
                <input class="calendar-textbox" placement="left" (onHidden)="setCalanderFlag()" [disabled]="!component.dateFrom" [minDate]="component.dateFrom" placeholder="To" bsDatepicker [bsConfig]="{dateInputFormat : 'MM/DD/YYYY', showWeekNumbers:false }" [(ngModel)]="component.dateTo" [outsideClick]="true" />

              </div>
            </div>
            <div class="row" *ngIf="component.componentType === 'date' && (component.selectedOption === 'equals' || component.selectedOption === 'gt' || component.selectedOption === 'lt' || component.selectedOption === 'lte' )" >
              <div class="col-md-12">
                <input class="calendar-textbox" (onHidden)="setCalanderFlag()" placeholder="Date" placement="left" bsDatepicker [(ngModel)]="component.date" [bsConfig]="{ dateInputFormat : 'MM/DD/YYYY', showWeekNumbers:false }" [outsideClick]="true" />
              </div>
            </div>
            <div class="row" *ngIf="component.componentType === 'input'">
              <div class="col-md-4 pr-0" *ngIf="component.fieldKey !== 'invoice_number' && component.fieldKey !== 'c39' && component.fieldKey !== 'c13' && component.fieldKey !== 'Primary_Account_Number'  && component.fieldKey !== 'bank'">
                <span class="inner-text" *ngIf="!component.label; else caseLabelPresent">is equal to</span>
                <ng-template #caseLabelPresent>
                  <span class="inner-text">{{ component.label }}</span>
                </ng-template>
              </div>
              <div class="col-md-8 pl-0" >
                <input class="inner-input {{component.fieldKey === 'invoice_number' || component.fieldKey === 'c39' || component.fieldKey === 'c13'|| component.fieldKey === 'Primary_Account_Number' || component.fieldKey === 'bank' ? 'ml-5' : ''}}" type="text" [(ngModel)]="component.inputValue">
              </div>
            </div>
            <div class="row " *ngIf="component.componentType === 'number' && component.selectedOption === 'between'">
              <div class="col-md-4 pl-3 pr-0">
                <input class="inner-input" type="number" min="0" [(ngModel)]="component.numberFrom">
              </div>
              <div class="col-md-2 pl-1 pr-1 betweenText">and</div>
              <div class="col-md-4 pl-0">
                <input class="inner-input" type="number" min="0" [(ngModel)]="component.numberTo">
              </div>
            </div>
            <div class="row " *ngIf="component.componentType === 'number' && component.selectedOption !== 'between'">
              <div class="col-md-4 pl-3 pr-0">
                <input class="inner-input" type="number"  min="0" [(ngModel)]="component.number">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
