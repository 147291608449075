import {FilterModel} from '../../data/model/filteModel/filter-model';


export class FilterConstants {
  /*storage keys for filter*/
  // for customer list
  public static CUSTOMER_FILTER_STORAGE_KEY = 'customer_filter_storage_key';
  public static CUSTOMER_FILTER_COUNT_STORAGE_KEY = 'customer_filter_count_storage_key';
  // for payment list
  public static PAYMENT_FILTER_STORAGE_KEY = 'payment_filter_storage_key';
  public static PAYMENT_FILTER_COUNT_STORAGE_KEY = 'payment_filter_count_storage_key';
  // for disputes
  public static DISPUTE_FILTER_STORAGE_KEY = 'disputes_filter_storage_key';
  public static DISPUTE_FILTER_COUNT_STORAGE_KEY = 'disputes_filter_count_storage_key';
  public static DISPUTE_FROM_TO_DATE_KEY = 'disputes_from_to_date_key';
  // for disputes report
  public static DISPUTE_REPORT_FILTER_STORAGE_KEY = 'disputes_report_filter_storage_key';
  public static DISPUTE_REPORT_FILTER_COUNT_STORAGE_KEY = 'disputes_report_filter_count_storage_key';
  // for subscription list
  public static SUBSCRIPTION_FILTER_STORAGE_KEY = 'subscription_filter_storage_key';
  public static SUBSCRIPTION_FILTER_COUNT_STORAGE_KEY = 'subscription_filter_count_storage_key';
  // FOR CYCLE
  public static CYCLE_FILTER_STORAGE_KEY = 'cycle_filter_storage_key';
  public static CYCLE_FILTER_COUNT_STORAGE_KEY = 'cycle_filter_count_storage_key';
  // FOR COUPON
  public static COUPON_FILTER_STORAGE_KEY = 'coupon_filter_storage_key';
  public static COUPON_FILTER_COUNT_STORAGE_KEY = 'coupon_filter_count_storage_key';
  // FOR INVOICE
  public static INVOICE_FILTER_STORAGE_KEY = 'invoice_filter_storage_key';
  public static INVOICE_FILTER_COUNT_STORAGE_KEY = 'invoice_filter_count_storage_key';
  // FOR PAYOUTS
  public static PAYOUTS_FILTER_STORAGE_KEY = 'payout_filter_storage_key';
  public static PAYOUTS_FILTER_COUNT_STORAGE_KEY = 'payout_filter_count_storage_key';
  // FOR TRANSACTIONS
  public static TRANSACTIONS_FILTER_STORAGE_KEY = 'transaction_filter_storage_key';
  public static TRANSACTIONS_FILTER_COUNT_STORAGE_KEY = 'transaction_filter_count_storage_key';

  // for review
  public static REVIEW_FILTER_STORAGE_KEY = 'review_filter_storage_key';
  public static REVIEW_FILTER_COUNT_STORAGE_KEY = 'review_filter_count_storage_key';

  // for other payments
  public static OTHERPAYMENT_FILTER_STORAGE_KEY = 'otherpayment_filter_storage_key';
  public static OTHERPAYMENT_FILTER_COUNT_STORAGE_KEY = 'otherpayment_filter_count_storage_key';
  // for payments

  public static PAYMENTS_FILTER_STORAGE_KEY = 'payments_filter_storage_key';
  public static PAYMENTS_FILTER_COUNT_STORAGE_KEY = 'payments_filter_count_storage_key';

// for payment invoice filter key
  public static PAYMENT_INVOICE_FILTER_STORAGE_KEY = 'payment_invoice_filter_storage_key';
  public static PAYMENT_INVOICE_FILTER_COUNT_STORAGE_KEY = 'payment_invoice_filter_count_storage_key';

  // for split_funding filter key
  public static SPLIT_FUNDING_FILTER_STORAGE_KEY = 'split_funding_filter_storage_key';
  public static SPLIT_FUNDING_FILTER_COUNT_STORAGE_KEY = 'split_funding_filter_count_storage_key';

  // for payee summary filter key
  public static PAYEE_SUMMARY_FILTER_STORAGE_KEY = 'payee_summary_filter_storage_key';
  public static PAYEE_SUMMARY_FILTER_COUNT_STORAGE_KEY = 'payee_summary_filter_count_storage_key';

  // for campaign summary filter key
  public static CAMPAIGN_SUMMARY_FILTER_STORAGE_KEY = 'campaign_summary_filter_storage_key';
  public static CAMPAIGN_SUMMARY_FILTER_COUNT_STORAGE_KEY = 'campaign_summary_filter_count_storage_key';

  // for zendesk summary filter key
  public static ZENDESK_FILTER_STORAGE_KEY = 'zendesk_filter_storage_key';
  public static ZENDESK_FILTER_COUNT_STORAGE_KEY = 'zendesk_filter_count_storage_key';
  /*data for filters*/
  public static customerListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    // {
    //   componentType: 'text',
    //   heading: 'Has expired card',
    //   fieldKey: 'card[inactive]=true',
    //   checked: false,
    //   selectedOption: null,
    //   subOption: null,
    //   selectedSubOption: null,
    //   dayCount: null,
    //   date: null,
    //   dateFrom: null,
    //   dateTo: null,
    //   inputValue: null,
    //   number: null,
    //   numberFrom: null,
    //   numberTo: null
    // },
    /*{
     componentType: 'list',
     heading: 'Discount',
     fieldKey: 'discount',
     checked: false,
     options: [
     {key: 'false', value: 'does not have a discount'},
     {key: 'true', value: 'has a discount'},
     {key: '43', value: 'asdf'},
     {key: '54', value: '54'},
     ],
     selectedOption: null,
     subOption: null,
     selectedSubOption: null,
     dayCount: null,
     date: null,
     dateFrom: null,
     dateTo: null,
     inputValue: null,
     number: null,
     numberFrom: null,
     numberTo: null

     },*/
    // {
    //   componentType: 'list',
    //   heading: 'Delinquent',
    //   fieldKey: 'delinquent',
    //   checked: false,
    //   options: [
    //     {key: 'true', value: 'is delinquent'},
    //     {key: 'false', value: 'is paid up'}
    //   ],
    //   selectedOption: null,
    //   subOption: null,
    //   selectedSubOption: null,
    //   dayCount: null,
    //   date: null,
    //   dateFrom: null,
    //   dateTo: null,
    //   inputValue: null,
    //   number: null,
    //   numberFrom: null,
    //   numberTo: null
    //
    // },
    {
      componentType: 'input',
      heading: 'Email',
      fieldKey: 'email',
      checked: false,
      options: null,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'input',
      heading: 'Merchant',
      // below ---> fieldKey: 'merchant' needs to change according to DB field value, //deyan prc-2096
      fieldKey: 'merchant',
      checked: false,
      options: null,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];
  public static payeeSummaryFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Dashboard Access',
      fieldKey: 'dashboard_access',
      checked: false,
      options: [
        {key: '1', value: 'Yes'},
        {key: '0', value: 'No'},
      ],
      selectedOption: null,
    },
    {
      componentType: 'input',
      heading: 'Campaign',
      fieldKey: 'search=name:',
      checked: false,
      options: null,
      selectedOption: null,
      inputValue: null,
    }
  ];
  public static campaignSummaryFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Campaign Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'active', value: 'Active'},
        {key: 'inactive', value: 'Inactive'},

      ],
      selectedOption: null,


    },
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
        {key: 'month', value: 'Months'}
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
 public static paymentsFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Card Type',
      fieldKey: 'Card_Type',
      checked: false,
      options: [
        {key: '5', value: 'Master'},
        {key: '8', value: 'Discover'},
        {key: '3', value: 'Amex'},
        {key: '4', value: 'Visa'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Authorized Amount',
      fieldKey: 'Authorized_Amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Transaction Date',
      fieldKey: 'Transaction_Date_and_Time',
      checked: false,
      options: [
        // {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        // {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
   {
      componentType: 'input',
      heading: 'Card Number',
      fieldKey: 'Primary_Account_Number',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static paymentListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
        {key: 'month', value: 'Months'}
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'text',
      heading: 'Under Review',
      fieldKey: 'is_under_review[eq]=1',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'authorized', value: 'Authorized'},
        {key: 'authorization_expired', value: 'Authorization expired'},
        {key: 'settled', value: 'Settled'},
        {key: 'submitted_for_settlement', value: 'Submitted for settlement'},
        {key: 'void', value: 'Void'},
        {key: 'partial_refund', value: 'Partially refunded'},
        {key: 'failed_by_gateway', value: 'Failed by gateway'},
        {key: 'refunded', value: 'Refunded'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },

    {
      componentType: 'list',
      heading: 'Refunded',
      fieldKey: 'refunded',
      checked: false,
      options: [
        {key: true, value: 'is fully refunded'},
        {key: false, value: 'is not refunded'},
        {key: 'false&amount_refunded[gt]=0', value: 'is partially refunded'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },
    {
      componentType: 'list',
      heading: 'Captured',
      fieldKey: 'capture',
      checked: false,
      options: [
        {key: true, value: 'has been captured'},
        {key: false, value: 'has not been captured'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },
    {
      componentType: 'list',
      heading: 'Disputed',
      fieldKey: 'disputed',
      checked: false,
      options: [
        {key: true, value: 'is disputed'},
        {key: false, value: 'is not disputed'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    }
  ];
  public static taxiPaymentListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
        {key: 'month', value: 'Months'}
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'text',
      heading: 'Under Review',
      fieldKey: 'is_under_review[eq]=1',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'authorized', value: 'Authorized'},
        {key: 'authorization_expired', value: 'Authorization expired'},
        {key: 'settled', value: 'Settled'},
        {key: 'submitted_for_settlement', value: 'Submitted for settlement'},
        {key: 'void', value: 'Void'},
        {key: 'partial_refund', value: 'Partially refunded'},
        {key: 'failed_by_gateway', value: 'Failed by gateway'},
        {key: 'refunded', value: 'Refunded'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },
    {
      componentType: 'list',
      heading: 'Refunded',
      fieldKey: 'refunded',
      checked: false,
      options: [
        {key: true, value: 'is fully refunded'},
        {key: false, value: 'is not refunded'},
        {key: 'false&amount_refunded[gt]=0', value: 'is partially refunded'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },
    {
      componentType: 'list',
      heading: 'Captured',
      fieldKey: 'capture',
      checked: false,
      options: [
        {key: true, value: 'has been captured'},
        {key: false, value: 'has not been captured'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },
    {
      componentType: 'list',
      heading: 'Disputed',
      fieldKey: 'disputed',
      checked: false,
      options: [
        {key: true, value: 'is disputed'},
        {key: false, value: 'is not disputed'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    }
  ];
  public static OtherPaymentListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Transaction date',
      fieldKey: 'Transaction_Date',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
        {key: 'month', value: 'Months'}
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Transaction Amount',
      fieldKey: 'c10',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'input',
      heading: 'Authorization Number',
      fieldKey: 'c39',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },

    {
      componentType: 'input',
      heading: 'Cardholder Account Number',
      fieldKey: 'c13',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];
  public static disputeListFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'resolution_to',
      checked: false,
      options: [
        {key: 'B', value: 'Resolved to Issuing Bank'},
        {key: 'M', value: 'Resolved to  Merchant'},
        {key: 'G', value: 'Resolved to  Acquirer\'s General Ledger'},
        {key: 'S', value: 'Split'},
        {key: 'C', value: ' Credit (offsetting case)'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'case_amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  /*  {
      componentType: 'date',
      heading: 'Dispute date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },*/

    /*{

      componentType: 'date',
      heading: 'Evidence due by',
      fieldKey: 'reply_by',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }*/
  ];

  public static disputeReportFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Reason',
      fieldKey: 'reason',
      checked: false,
      options: [
        {key: '4853', value: 'Cardholder Dispute'},
        {key: '4863', value: 'Cardholder does not recognize transaction'},
        {key: '0041', value: 'Cancelled Recurring Transaction'},
        {key: '0077', value: 'Non-Matching Account Number'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'Open', value: 'Open'},
        {key: 'Won', value: 'Won'},
        {key: 'Lost', value: 'Lost'},
        {key: 'Disputed', value: 'Disputed'},
        {key: 'Accepted', value: 'Accepted'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static subscriptionListFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Payment Type',
      fieldKey: 'payment_type',
      checked: false,
      options: [
        {key: 'card', value: 'Credit Card'},
        {key: 'ach', value: 'ACH'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null

    },
    {
      componentType: 'list',
      heading: 'Plan',
      fieldKey: 'plan',
      checked: false,
      options: [],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'trial', value: 'is trialing'},
        {key: 'active', value: 'is active'},
        {key: 'past_due', value: 'is past due'},
        {key: 'unpaid', value: 'is unpaid'},
        {key: 'canceled', value: 'is cancelled'},
        {key: 'any', value: 'is any of the above'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];
  public static splitFundingFilter: FilterModel[] = [
    // {
    //   componentType: 'list',
    //   heading: 'Plan',
    //   fieldKey: 'plan',
    //   checked: false,
    //   options: [],
    //   selectedOption: null,
    //   subOption: null,
    //   selectedSubOption: null,
    //   dayCount: null,
    //   date: null,
    //   dateFrom: null,
    //   dateTo: null,
    //   inputValue: null,
    //   number: null,
    //   numberFrom: null,
    //   numberTo: null
    // },
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'inactive', value: 'Inactive'},
        {key: 'active', value: 'Active'},

      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];
  public static couponListFilter: FilterModel[] = [
    {
      componentType: 'number',
      heading: 'Percent off',
      fieldKey: 'percent_off',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Maximum redemption',
      fieldKey: 'max_redemptions',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Redeem by',
      fieldKey: 'redeem_by',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Duration',
      fieldKey: 'duration',
      checked: false,
      options: [
        {key: 'forever', value: 'is forever'},
        {key: 'once', value: 'is once'},
        {key: 'repeating', value: 'is repeating'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];
  public static invoiceListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'total',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'input',
      heading: 'Invoice Number',
      fieldKey: 'invoice_number',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    /*
    {
      componentType: 'list',
      heading: 'Paid',
      fieldKey: 'paid',
      checked: false,
      options: [
        {key: true, value: 'Paid'},
        {key: false, value: 'Unpaid'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    */
    {
      componentType: 'list',
      heading: 'Draft',
      fieldKey: 'draft',
      checked: false,
      options: [
        {key: true, value: 'Draft'},
        {key: false, value: 'Not Draft'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Closed',
      fieldKey: 'closed',
      checked: false,
      options: [
        {key: true, value: 'Closed'},
        {key: false, value: 'Open'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Attempted',
      fieldKey: 'attempted',
      checked: false,
      options: [
        {key: true, value: 'Attempted'},
        {key: false, value: 'Unattempted'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Forgiven',
      fieldKey: 'forgiven',
      checked: false,
      options: [
        {key: true, value: 'Forgiven'},
        {key: false, value: 'Not Forgiven'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }, /*{
     componentType: 'date',
     heading: 'Due date',
     fieldKey: 'due_at',
     checked: false,
     options: [
     {key: '[inlt]', value: 'is in the last'},
     {key: 'equals', value: 'is equal to'},
     {key: 'between', value: 'is between'},
     {key: 'gt', value: 'is after'},
     {key: 'lt', value: 'is before'},
     {key: 'lte', value: 'is before or on'},
     ],
     selectedOption: null,
     subOption: [
     {key: 'day', value: 'Days'},
     {key: 'month', value: 'Months'}
     ],
     selectedSubOption: null,
     dayCount: null,
     date: null,
     dateFrom: null,
     dateTo: null,
     inputValue: null,
     number: null,
     numberFrom: null,
     numberTo: null
     },*/
    /*{
     componentType: 'list',
     heading: 'Billing',
     fieldKey: 'billing',
     checked: false,
     options: [
     {key: 'charge_automatically', value: 'is charged automatically'},
     {key: 'send_invoice', value: 'is sent invoice'},
     ],
     selectedOption: null,
     subOption: null,
     selectedSubOption: null,
     dayCount: null,
     date: null,
     dateFrom: null,
     dateTo: null,
     inputValue: null,
     number: null,
     numberFrom: null,
     numberTo: null
     }*/
  ];
  public static zendeskListFilter: FilterModel[] = [
    {
      componentType: 'input',
      heading: 'Subject',
      fieldKey: 'subject',
      label: 'contains',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'input',
      heading: 'Description',
      fieldKey: 'description',
      label: 'contains',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Last Updated',
      fieldKey: 'updated_at',
      checked: false,
      options: [
        {key: 'between', value: 'is between'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'input',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static payoutListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Available at',
      fieldKey: 'arrival_date',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }/*,
    {
      componentType: 'input',
      heading: 'Bank',
      fieldKey: 'bank',
      checked: false,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }*/

  ];
  public static transactionListFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Type',
      fieldKey: 'type',
      checked: false,
      options: [
        {key: 'charges', value: 'Charges'},
        {key: 'refunds', value: 'Refunds'},
        {key: 'disputes', value: 'Disputes'},
        {key: 'payouts', value: 'Payouts'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];

  public static cycleListFilter: FilterModel[] = [
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'input',
      heading: 'Name',
      fieldKey: 'name',
      checked: false,
      options: null,
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Interval',
      fieldKey: 'interval',
      checked: false,
      options: [
        {key: 'day', value: 'Days'},
        {key: 'week', value: 'Weeks'},
        {key: 'month', value: 'Months'},
        {key: 'year', value: 'Years'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];


  public static reviewFilter: FilterModel[] = [
    {
      componentType: 'list',
      heading: 'Review Description',
      fieldKey: 'review_desc',
      checked: false,
      options: [
        {key: 'single_transaction_value_limit', value: 'Single Transaction Value Limit'},
        {key: 'daily_transaction_value_limit', value: 'Daily Transaction Value Limit'},
        {key: 'daily_transaction_count_limit', value: 'Daily Transaction Count Limit'},
        {key: 'hourly_transaction_count_limit', value: 'Hourly Transaction Count Limit'},
        {key: 'hourly_transaction_value_limit', value: 'Hourly Transaction Value Limit'},
        {key: 'monthly_transaction_count_limit', value: 'Monthly Transaction Count Limit'},
        {key: 'monthly_transaction_value_limit', value: 'Monthly Transaction Value Limit'},
        {key: 'hourly_invalid_card_count_limit', value: 'Hourly Invalid Card Count Limit'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
    },
    {
      componentType: 'date',
      heading: 'Date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Action',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'approved', value: 'Approved'},
        {key: 'rejected', value: 'Rejected'},
        {key: 'waiting_for_approval', value: 'Waiting for approval'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
    }
  ];

  public static paymentInvoiceFilter: FilterModel[] = [
    {
      componentType: 'date',
      heading: 'Created date',
      fieldKey: 'created_at',
      checked: false,
      options: [
        {key: '[intl]=', value: 'is in the last'},
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'gt', value: 'is after'},
        {key: 'lt', value: 'is before'},
        {key: 'lte', value: 'is before or on'},
      ],
      selectedOption: null,
      subOption: [
        {key: 'day', value: 'Days'},
      ],
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'number',
      heading: 'Amount',
      fieldKey: 'amount',
      checked: false,
      options: [
        {key: 'equals', value: 'is equal to'},
        {key: 'between', value: 'is between'},
        {key: 'greaterThan', value: 'is greater than'},
        {key: 'lessThan', value: 'is less than'}
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },

    {
      componentType: 'list',
      heading: 'Invoice type',
      fieldKey: 'send_via',
      checked: false,
      options: [
        {key: 'sms', value: 'SMS'},
        {key: 'email', value: 'Email'},
        {key: 'email,sms', value: 'Both'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      options: [
        {key: 'SUCCESS', value: 'Success'},
        {key: 'Failed', value: 'Failed'},
        {key: 'order_under_Process', value: 'Order under process'},
      ],
      selectedOption: null,
      subOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    }
  ];
}
