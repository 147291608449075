<div class="subs-bdy">
<div class="container-fluid b_shadow mt-5">
  <div class="row create-header bg-infos">
     <span class="header"> {{heading}} </span>
  </div>
  <div class="col-lg-12 col-12 col-sm-12 col-md-12 my-lg-4 my-md-3">
     <div class="row">
        <ng-container *ngIf="topErrorMsg">
           <div class="row" *ngFor="let value of values">
              <div class="col-sm-12 error mb-3" *ngFor="let obj of value">
                 <div class="col-sm-12 error mb-3"> {{obj}}</div>
              </div>
           </div>
        </ng-container>
     </div>
     <div class="row">
        <p class="col-md-12 error mb-3" id="err"> {{errorMsg}}</p>
     </div>
     <form #couponDialog="ngForm" (ngSubmit)="onSubmit(couponDialog)">
     <div class="row">
         <div class="col-sm-12 col-md-12 col-lg-12 col-12 col-xl-8 mb-4">
           <div class="form-group typeahead_formcontainer row ">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m">Select customer:<i class="required-icon">*</i></label>
              <div class="col-sm-6 col-md-6 pl-0">
                 <app-type-ahead-component *ngIf="!alreadySelectedCustomerObj && !newSubscription" (selectedObject)="customerSelected($event)" [serviceName]="customerService_name" [disable]="false" [selectedValue]="null" [require]="true" (keyup)=" deleteCustomer()"></app-type-ahead-component>
                 <app-type-ahead-component (keyup)=" deleteCustomer()" *ngIf="!alreadySelectedCustomerObj && newSubscription" (selectedObject)="customerSelected($event)" [serviceName]="customerService_name" [disable]="true" [selectedValue]="selectedCustomerObj?.email ? selectedCustomerObj?.email : selectedCustomerObj?.customer_id "
                 [require]="true"></app-type-ahead-component>
                 <app-type-ahead-component (keyup)=" deleteCustomer()" *ngIf="alreadySelectedCustomerObj && !newSubscription" (selectedObject)="customerSelected($event)" [serviceName]="customerService_name" [disable]="true" [selectedValue]="alreadySelectedCustomerObj.email ? alreadySelectedCustomerObj.email : alreadySelectedCustomerObj.customer_id"
                 [require]="true"></app-type-ahead-component>
              </div>
           </div>
         <div class="form-group row  align-items-baseline">
            <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4  padding-m section-top">Payment Type:<i  class="required-icon">*</i></label>
            <div class="col-md-6 col-lg-8 col-sm-6 col-12 col-xl-8 no-padding">
               <div class="row no-margin align-items-center">
                  <div class="col-12 pl-0 d-flex mb-3">
                     <label class="check-box-container pr-2">
                      <input class=""  type="radio" name="radio_payment" (click)="paymentType('card')"  [attr.disabled]="newSubscription ? '' : null " >
                      <input class=""  type="radio" name="radio_payment" (click)="paymentType('card')" [checked]="newSubscription !== null ? newSubscription.payment_type==='card' : payment_type==='card'" [value]="true" >
                      Card
                      <span class="checkmark"></span>
                      </label>
                     <label *ngIf="setEnableAchPayments" class="check-box-container manual-align">
                      <input *ngIf="setEnableAchPayments" type="radio" name="radio_payment"  class="radiobtn" (click)="paymentType('ach')"
                      [attr.disabled]="newSubscription ? '' : null" >
                      <input type="radio" name="radio_payment"  class="radiobtn" (click)="paymentType('ach')" *ngIf="setEnableAchPayments" [checked]="newSubscription !== null ? newSubscription.payment_type==='ach' : payment_type==='ach'"  >
                      ACH
                      <span class="checkmark"></span>
                      </label>
                    <label *ngIf="this.billing_type === 0"  class="check-box-container ml-2">
                      <input class=""  type="radio" name="radio_payment" (click)="paymentType('both')"  [checked]="newSubscription !== null ? newSubscription.payment_type==='both' : payment_type==='both' ">
<!--                      <input class=""  type="radio" name="radio_payment" (click)="paymentType('both')" [checked]="newSubscription !== null ? newSubscription?.payment_type==='both' ?? 1 : true "[value]="true" >-->
                     Both
                      <span class="checkmark"></span>
                    </label>
                  </div>
               </div>
            </div>
         </div>
           <div class="form-group row typeahead_formcontainer ">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m">Select plan:<i class="required-icon">*</i></label>
              <div class="col-sm-6 col-md-6 pl-0">
                 <app-type-ahead-component *ngIf="!newSubscription" (selectedObject)="planSelected($event); edittrail()" [serviceName]="palnService_name" [selectedValue]="null" [disable]="false" [require]="true" (keyup)="deletePlan()"></app-type-ahead-component>
                 <app-type-ahead-component *ngIf="newSubscription" (selectedObject)="planSelected($event); edittrail()" (keyup)="deletePlan()" [serviceName]="palnService_name" [selectedValue]="(newSubscription.plan.data.name ? newSubscription.plan.data.name : '' ) + ' ( ' + ' ) ' + (newSubscription.plan.data.plan_id ? ' - ' + newSubscription.plan.data.plan_id : '')"
                 [require]="true" [disable]="true"></app-type-ahead-component>
              </div>
           </div>
           <div class="form-group row typeahead_formcontainer ">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m">Select discount:</label>
              <div class="col-sm-6 col-md-6 pl-0">
                 <app-type-ahead-component *ngIf="!newSubscription" (selectedObject)="couponSelected($event)" [serviceName]="couponService_name" [disable]="false" [selectedValue]="null" [require]="false" (keyup)="deleteCoupon()"></app-type-ahead-component>
                 <app-type-ahead-component *ngIf="newSubscription" (selectedObject)="couponSelected($event)" [serviceName]="couponService_name" [disable]="true" (keyup)="deleteCoupon()" [selectedValue]="selectedCouponObj ? ((selectedCouponObj.percent_off
                 || selectedCouponObj.amount_off) ? ((selectedCouponObj.percent_off ?
                 (selectedCouponObj.percent_off + '%') : (selectedCouponObj.amount_off/100 + ' USD'))
                 + ' off ' + selectedCouponObj.duration) : selectedCouponObj.discount_id) : null " [require]="false">
                 </app-type-ahead-component>
              </div>
           </div>
           <div class="form-group row ">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m">Tax:</label>
              <div class="col-sm-6 col-md-6 pl-0">
                 <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" appMinMaxValidator class="form-control" [min]="0" [max]="100" id="tax_percent" maxLength="5" [class.invalid]="taxpercentage.invalid && couponDialog.submitted"
                 [(ngModel)]="tax_percent" name="taxpercentage" #taxpercentage="ngModel" placeholder="0%">
                 <span class="validation-err-msg" *ngIf="couponDialog.submitted && taxpercentage.errors?.min">Tax can not be less than 0%. </span>
                 <span class="validation-err-msg" *ngIf="couponDialog.submitted && taxpercentage.errors?.max">Tax can not be greater than 100%. </span>
              </div>
           </div>
           <div class="form-group row  align-items-baseline section-top">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m">Include trial period:</label>
              <div class="col-md-6 col-lg-8 col-xl-8 col-12 col-sm-6 no-padding  p-18">
                 <div class="row no-margin align-items-center">
                    <input type="checkbox" min="0" name="inc_trial" id="inc_trial" class="form-check-input" [disabled]="newSubscription" [(ngModel)]='checkbox' (change)="show = !show; onCheckboxChange()" checked>
                    <label class="p-l-r m-0">Duration</label>
                    <input type="number" class="form-control col-2 text-center trail-period-days" min="0" max="999" name="edittrial" maxlength="3" (keypress)="_keyPress($event)" [(ngModel)]="selectedPlanObj.trial_period_days" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    #edittrial="ngModel" id="trial_days" [disabled]="show || newSubscription">
                    <label class="p-l-r">days</label>
                 </div>
              </div>
           </div>
           <div class="form-group row  align-items-baseline" *ngIf="show">
              <label class="col-sm-6 col-md-6 col-lg-4 col-6 col-xl-4">Subscription starts:<i *ngIf="!start_after_days_disabled" class="required-icon">*</i></label>
              <div class="col-md-6 col-lg-8 col-xl-8 col-6 col-sm-6 no-padding">
                 <div class="row no-margin align-items-center">
                    <label class="check-box-container pr-2">
                     <input type="radio" name="startradio" [value]="true" [(ngModel)]="start_after_days_disabled">
                     <span class="checkmark"></span>
                     Starts immediately
                     </label>
                     <label class="check-box-container pr-2">
                     <input type="radio" name="startradio" class="" [value]="false" [(ngModel)]="start_after_days_disabled">
                     <span class="checkmark"></span>
                     starts after
                     </label>
                    <input type="text" class="form-control col-2 text-center " appMinMaxValidator [class.invalid]="start_after_day.invalid && couponDialog.submitted" [min]="1" [max]="999" name="start_after_day" maxlength="3" [(ngModel)]="start_after_days" (keypress)="_keyPress($event)"
                    id="start_after_days" required [disabled]="start_after_days_disabled" #start_after_day="ngModel">
                    <label class="day ">days</label>
                    <span class="validation-err-msg ie11" style="bottom: 0" *ngIf="start_after_day.errors?.required && couponDialog.submitted">Starts after is required.</span>
                    <span class="validation-err-msg invaliderrsubscrib" style="bottom: 0" *ngIf="(start_after_day.errors?.min || start_after_day.errors?.max) && couponDialog.submitted">Starts after is invalid.</span>
                 </div>
              </div>
           </div>
           <div class="form-group row  align-items-baseline section-top">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m">Subscription ends:<i *ngIf="end_after_cycles_disabled" class="required-icon">*</i></label>
              <div class="col-md-6 col-lg-8 col-xl-8 col-12 col-sm-6 no-padding">
                 <div class="row no-margin align-items-center">
                     <label class="check-box-container pr-2" >
                     <input type="radio" name="radio" [disabled]="end_after_cycles_disabled"  [value]="newSubscription !== null ? newSubscription.end_at ===null : true"  [(ngModel)]="end_after_cycles_disabled">
                     <span class="checkmark"></span>
                     Never
                     </label>
                     <label class="check-box-container pr-2 subscription-end">
                     <input type="radio" name="radio" id="endradio" class=" "  [value]="newSubscription !== null ? newSubscription.end_at !==null : false" [(ngModel)]="end_after_cycles_disabled">
                     ends after
                     <span class="checkmark"></span>
                     </label>
                    <span class="billing-align">
                    <input type="text" class="form-control col-2 text-center " required appMinMaxValidator [min]="1" [max]="999" [class.invalid]="couponDialog.submitted && end_after.invalid" name="end_after" maxlength="3" [(ngModel)]="end_after_cycles" (keypress)="_keyPress($event)"
                    id="end_after_cycles" [disabled]="newSubscription?.end_at? !end_after_cycles_disabled : end_after_cycles_disabled" #end_after="ngModel">
                    <label class="p-l-r">billing cycle(s)</label>
                    </span>
                    <div class="end_after_error" *ngIf="couponDialog.submitted && end_after.errors?.required" style="bottom: 0">End after is required.</div>
                    <div class="validation-err-msg invaliderrsubscrib" *ngIf="couponDialog.submitted && (end_after.errors?.max || end_after.errors?.min)" style="bottom: 0">End after is invalid.</div>
                    <span id="billingcyc">{{selectedPlanObj.interval_count ? '(A billing cycle is ' + selectedPlanObj.interval_count : ''}} {{selectedPlanObj.interval ? selectedPlanObj.interval + '(s))' : ''}}</span>
                 </div>
              </div>
           </div>
           <div class="form-group row  align-items-baseline">
              <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4  padding-m section-top">Billing type:<i *ngIf="!billing_type_disabled" class="required-icon">*</i></label>
              <div class="col-md-6 col-lg-8 col-sm-6 col-12 col-xl-8 no-padding">
                 <div class="row no-margin align-items-center">
                    <div class="col-12 pl-0 d-flex mb-3">
                        <label class="check-box-container pr-2">
                           <input class="" *ngIf="newSubscription" type="radio" name="radio1" (click)="billingType(1)" onclick="document.getElementById('payment_due_days').disabled = false;" [checked]="( newSubscription.billing_type === 1 ) ? true : false" [value]="true"  [(ngModel)]="billing_type_disabled"  >
                           <input class="" *ngIf="!newSubscription" type="radio" name="radio1" (click)="billingType(1)" onclick="document.getElementById('payment_due_days').disabled = true;"  [value]="true" [(ngModel)]="billing_type_disabled">

                           Automatic
                           <span class="checkmark"></span>
                           </label>
                          <label class="check-box-container manual-align">
                           <input type="radio" name="radio1" *ngIf="newSubscription" class="radiobtn" (click)="billingType(0)" onclick="document.getElementById('payment_due_days').disabled = false" [checked]="(newSubscription.billing_type === 0  ) ? true : false" [value]="false"
                           [(ngModel)]="billing_type_disabled"  >
                           <input type="radio" name="radio1" *ngIf="!newSubscription" class="radiobtn" (click)="billingType(0)" onclick="document.getElementById('payment_due_days').disabled = false" [value]="false" [(ngModel)]="!billing_type_disabled">
                           Manual
                           <span class="checkmark"></span>

                        </label>
                    </div>
                    <div class="col-12 d-contents d-m-payment">
                       <label class="pt-1 pr-1">payment due:</label>
                       <input type="text" class="form-control col-2 text-center " appMinMaxValidator [class.invalid]="payment_due_day.invalid && couponDialog.submitted" [min]="1" [max]="999" name="payment_due_day" maxlength="3" [(ngModel)]="payment_due_days" (keypress)="_keyPress($event)"
                       id="payment_due_days" required [disabled]="billing_type_disabled" #payment_due_day="ngModel">
                       <label class="p-l-r">days after invoice is sent</label>
                    </div>
                    <div class="end_after_error" *ngIf="couponDialog.submitted && payment_due_day.errors?.required" style="bottom: 0">Payment Due Days field is required.</div>
                 </div>
              </div>
           </div>
           <div class="form-group row d-inline">

           </div>
           <div class="memo-text col-12 col-lg-8 col-md-12 col-sm-12 col-xl-8 p-0">
              <p class="memo">Memo</p>
              <textarea rows="3" class="form-control" [(ngModel)]="description" [class.invalid]="mem.invalid && couponDialog.submitted" minlength="5" id="memo" name="mem" maxlength="25" #mem="ngModel"></textarea>
              <small class="memo-char-count" *ngIf="description">{{25 - description.length}} left</small>
              <span class="validation-err-msg position-relative" *ngIf="mem.errors?.minlength && couponDialog.submitted">The Memo must be at least 5 characters.</span>
              <p id="txt" class="text-top" *ngIf="!(mem.invalid && couponDialog.submitted)">This will appear on invoices</p>
           </div>
           <div class="mt-0">
              <p class="memo text-top">Preview</p>
              <p class="memo text-top">Amount due on first invoice will be
                 <span *ngIf="!tax_percent">{{amountToPay ? ((amountToPay / 100) | currency:'USD':'symbol':'1.2-2') + ' USD' : '$0.00 USD'}}</span>
                 <span *ngIf="tax_percent">{{amountToPay ? (amountToPay / 100 + ((amountToPay / 100) * (tax_percent / 100)) | currency:'USD':'symbol': '1.2-2') + ' USD' : '$0.00 USD'}}</span>
              </p>
           </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-12 col-xl-4 px-md-3">
           <div class="form-group mb-4">
              <div class="input-selected-details">
                 <p>Customer ID: <span>{{selectedCustomerObj ? selectedCustomerObj.customer_id : ''}}</span></p>
                 <p>Email: <span>{{selectedCustomerObj?.email ? selectedCustomerObj.email : ''}}</span></p>
              </div>
           </div>
           <div class="form-group mb-4">
              <div class="input-selected-details">
                 <p>Plan ID: <span class="align_plan">{{selectedPlanObj.plan_id ? selectedPlanObj.plan_id : ''}}</span>
                 </p>
                 <p id="plan">Amount:
                    <span>{{selectedPlanObj.amount ? ('$' + (selectedPlanObj.amount / 100 | number: '1.1-4')) : ''}}</span>
                    <span>{{selectedPlanObj.interval ? selectedPlanObj.interval === ' once' ? 'once' : ' every' : ''}}</span>
                    <span>{{selectedPlanObj.interval_count}}</span>
                    <span>{{selectedPlanObj.interval ? selectedPlanObj.interval + '(s)' : ''}}</span>
                 </p>
                 <p>
                    Trial period:
                    <span> {{selectedPlanObj.trial_period_days != null ? selectedPlanObj.trial_period_days + ' day(s)' : ''}} </span>
                 </p>
              </div>
           </div>
           <div class="form-group mb-4">
              <div class="input-selected-details">
                 <p>Discount ID: <span class="coupon align_plan">{{selectedCouponObj.discount_id ? selectedCouponObj.discount_id : ''}}</span>
                 </p>
                 <p>Redeemed: <span class="coupon">{{selectedCouponObj.percent_off ? selectedCouponObj.percent_off + '% off'
                    : (selectedCouponObj.amount_off ? '$' + (selectedCouponObj.amount_off / 100) : '')}}</span>
                 </p>
              </div>
           </div>
        </div>
     </div>
     <div class="row justify-content-end pt-3 pb-3">
        <button class="cancel_btn" (click)="cancelSubscription()">Cancel</button>
        <app-loading-button  (buttonClick)="this.couponDialog.invalid ? '' : createSubscription()" [disable]="!selectedCustomerObj?.customer_id && !selectedPlanObj.plan_id" [loadFlag]="loadFlag" type="submit" [buttonText]="buttonText"></app-loading-button>
     </div>
     </form>
  </div>
</div>
</div>
